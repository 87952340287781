import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'

const isString = (value) => {
  return typeof value === 'string' || value instanceof String
}

export const MarriageBagsTemplate = ({ title, data, isAdmin, helmet }) => {
  return (
    <section className="section">
      {helmet}
      <div className="container mx-auto">
        <div className="content">
          <section>
            <nav className="flex flex-wrap items-center justify-between p-3">
              <div className="flex items-center mr-6 text-base border-b-4 sm:text-lg md:text-xl border-brand">
                <span className="text-base font-semibold tracking-tight border-b-4 sm:text-lg md:text-xl ">
                  Marriage Bags : {data.sku}
                </span>
              </div>
            </nav>

            <div className="pt-5 pb-10 pr-5 md:flex">
              <div className="md:flex-shrink-0">
                <img
                  srcSet={
                    isString(data.productImage)
                      ? data.productImage
                      : data.productImage.childImageSharp.fluid.srcSet
                  }
                  className="h-auto rounded-md shadow-2xl sm:h-56 lg:h-64"
                  alt=""
                />

                <a
                  target="_blank"
                  className="flex items-center inline-block mt-3 font-medium text-indigo-500 underline"
                  href={
                    isString(data.productImage)
                      ? data.productImage
                      : data.productImage.childImageSharp.fluid.src
                  }
                >
                  <span>View Large</span>
                  <svg
                    className="w-4 h-4 mt-1 ml-1 transform -rotate-45"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M14 5l7 7m0 0l-7 7m7-7H3"
                    />
                  </svg>
                </a>

                <div className="flex items-center mt-4">
                  <button className="px-8 py-2 text-sm font-medium text-white bg-indigo-600 rounded hover:bg-indigo-500 focus:outline-none focus:bg-indigo-500">
                    <a href="tel:9944180533">Connect with us →</a>
                  </button>
                  <button className="p-2 mx-2 text-gray-600 border rounded-md hover:bg-gray-200 focus:outline-none">
                    <Link
                      to={`https://api.whatsapp.com/send?phone=919944180533&text=${
                        typeof window !== 'undefined'
                          ? window.location.href
                          : null
                      }%0AName:%20*${title}*%0ASKU:%20${
                        data.sku
                      }%0A*Your%20Comments*%0A#`}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z" />
                      </svg>
                    </Link>
                  </button>

                  <button className="p-2 text-gray-600 border rounded-md hover:bg-gray-200 focus:outline-none">
                    <a href="tel:9944180533">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                      >
                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.445 17.827c-3.684 1.684-9.401-9.43-5.8-11.308l1.053-.519 1.746 3.409-1.042.513c-1.095.587 1.185 5.04 2.305 4.497l1.032-.505 1.76 3.397-1.054.516z" />
                      </svg>
                    </a>
                  </button>
                </div>
              </div>
              <div className="mt-4 md:mt-0 md:ml-6">
                <div className="text-sm font-bold tracking-wide text-indigo-600 uppercase">
                  SKU: {data.sku}
                </div>
                <div className="block mt-1 text-lg font-semibold leading-tight text-gray-900 ">
                  {title}
                </div>
                {data.promotion?.meta?.shortdescription && (
                  <p className="mt-2 text-gray-600">
                    {data.promotion.meta.shortdescription}
                  </p>
                )}
                {data.isPriceVisible && (
                  <p className="mt-2 font-bold text-red-600">
                    Price: ₹ {data.unitPrice}
                  </p>
                )}
                <div className="pt-6 md:flex">
                  {data.collection &&
                    data.collection.map((collection, index) => {
                      return (
                        <div key={index} className="md:flex-shrink-0">
                          <a
                            target="_blank"
                            href={
                              isString(collection.image)
                                ? collection.image
                                : collection.image.childImageSharp.fluid.src
                            }
                          >
                            <img
                              className="p-1 mr-2 border-2 rounded-lg shadow-md md:w-24"
                              srcSet={
                                isString(collection.image)
                                  ? collection.image
                                  : collection.image.childImageSharp.fluid
                                      .srcSet
                              }
                              alt={collection.title}
                            />
                          </a>
                        </div>
                      )
                    })}
                </div>
              </div>

              {/* <div className="mt-4 md:mt-0 md:ml-6"> 
                <img className="w-1/3" src="http://localhost:8000/utility/bag-collection-view.jpg" />
              </div> */}
            </div>
          </section>
        </div>
      </div>

      {isAdmin && (
        <div className="container mx-auto">
          <div className="content">
            <pre>{JSON.stringify(data, null, 4)}</pre>
          </div>{' '}
        </div>
      )}
    </section>
  )
}

MarriageBagsTemplate.propTypes = {
  content: PropTypes.node,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const MarriageBagsPost = ({ data, location }) => {
  const { markdownRemark: post } = data
  console.log('Admin view', data)
  return (
    <Layout>
      <MarriageBagsTemplate
        data={data.markdownRemark.frontmatter}
        helmet={
          <Helmet titleTemplate="%s | Marriage bags">
            <title>{`${post.frontmatter.title}`}</title>
            {/* <meta
              name="description"
              content={`${post.frontmatter.promotion.meta.shortdescription || null}`}
            /> */}
            <meta
              data-react-helmet="true"
              name="robots"
              content="index, follow"
            />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
            <link
              rel="canonical"
              href={`https://svsprintersandbags.com${location.pathname}`}
            />
            <link
              rel="shortcut icon"
              href={'https://www.svsprintersandbags.com/utility/favicon.ico'}
              type="image/x-icon"
            />
            <link
              rel="icon"
              href={'https://www.svsprintersandbags.com/utility/favicon.ico'}
              type="image/x-icon"
            />
            <meta
              http-equiv="Content-type"
              content="text/html; charset=utf-8"
            />
            <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
            <meta
              name="google-site-verification"
              content={'ZrGtnc1-4jAS0UhTYcX5iX1At81gWQkuqnj3ehBug6I'}
            />
            <meta
              itemprop="image"
              content={'https://www.svsprintersandbags.com/utility/svs-og.png'}
            />
            <meta
              name="og_site_name"
              property="og:site_name"
              content={'https://svsprintersandbags.com'}
            />
            <meta
              name="og_title"
              property="og:title"
              content={post.frontmatter.title}
            />
            <meta
              name="og_url"
              property="og:url"
              content={`https://svsprintersandbags.com${location.pathname}`}
            />
            <meta property="og:locale" content="ta_IN" />
            {/* <meta name="description" property="og:description" content={`${post.frontmatter.promotion.meta.shortdescription || null}`} /> */}
            <meta
              property="og:image"
              content={'https://www.svsprintersandbags.com/utility/svs-og.png'}
            />
            <meta property="og:type" content="website" />
            <meta
              name="author"
              property="article:author"
              content={
                'https://www.facebook.com/people/Svs-Velmurugan/100007336728118'
              }
            />
            <meta
              property="website:publisher"
              content={
                'https://www.facebook.com/people/Svs-Velmurugan/100007336728118'
              }
            />
            <meta property="website:section" content="ecommerce" />
            {/* <meta property="website:tag" content={basicInformation.keyword} /> */}
            <meta property="fb:app_id" content={'100007336728118'} />
            <meta property="fb:page_id" content={'100007336728118'} />
            <meta name="revisit-after" content="7 days" />
            <script>
              {/* Google Tag Manager */}
              {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-TSZKXQ');
            `}
            </script>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-65417371-2"
            />
            <script async src="https://www.google-analytics.com/analytics.js" />
            <script>
              {/* Global site tag (gtag.js) - Google Analytics */}
              {`
            window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
            ga('create', 'UA-65417371-2', 'auto');
            ga('send', 'pageview');
          `}
            </script>
          </Helmet>
        }
        title={data.markdownRemark.frontmatter.title}
      />
    </Layout>
  )
}

MarriageBagsPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default MarriageBagsPost

export const pageQuery = graphql`
  query MarriageBagsPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        sku
        collection {
          title
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        productImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        isPriceVisible
        unitPrice
        inStock
        size
        dimensions
        promotion {
          meta {
            shortdescription
            keywords {
              keyword
            }
          }
        }
      }
    }
  }
`
